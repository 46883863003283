@import "colors";
@import "fonts";
@import url(//db.onlinewebfonts.com/c/623c7118249e082fe87a78e08506cb4b?family=Segoe+UI);

@font-face {
    font-family: 'Segoe UI';
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.eot');
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.eot?#iefix') format('embedded-opentype');
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.woff') format('woff');
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal; }

.templateList {
    font-family: $title-font;
    font-size: 14px;
    color: $grey;

    .list-group-item:first-child {
        color: $text; }

    .delete {
        color: $lt-grey; }

    .delete:hover {
        color: $warning; } }

.tempActionWrapper {
    display: flex;
    position: relative;
    float: right;
    right: 20px;

    .newButton {
        cursor: pointer;
        height: 40px;
        width: 70px; }

    .dotButton {
        position: relative;
        left: 20px;
        cursor: pointer;

        img {
            width: 36px;
            height: 40px; } } }

.actionDropdownItem {
    padding: 5px 22px;
    cursor: pointer;
    color: #707070;
    font-family: "Mulish", sans-serif;
    font-size: 18px;

    &:hover {
        background-color: #FFE7CC; }

    .dropdownName {
        color: #707070;
        margin-left: 10px;
        opacity: 1;
        letter-spacing: 0px;
        text-align: left;
        font-size: 18px;
        line-height: 23px;
        font-weight: 100;
        font-family: "Mulish", sans-serif; } }

.actionDropdownPopper .popper {
    padding: 0;
    z-index: 9999;
    left: -8px !important;
    border-radius: 10px;
    top: -7px !important; }

.actionDropDownList {
    padding: 0;
    margin: 10px 0;
    border-radius: 16px; }

.actionDropdown {

    .popper {
        left: -12px !important; } }

.contentCopy {
    position: relative;
    right: 180px;
    color: #707070; }

.contentShare {
    position: relative;
    right: 160px;
    color: #707070; }

.contentEdit {
    position: relative;
    right: 160px;
    color: #707070; }

.templateIcon {
    margin-bottom: 5px;
    margin-right: 16px; }

.text {
    font-size: 16px;
    line-height: 23px;
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    letter-spacing: 0px;
    color: #878991;
    margin-bottom: 0;
    margin-left: 33px; }

u {
    text-decoration: none;
    color: #44454B; }

.searchResultsLoadingContainer {
    background: trasparent;
    top: 0;
    left: 0;
    z-index: 99999;
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .searchResultsRing {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px; } }

.searchResultsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $orange;
    border-radius: 50%;
    animation: searchResultsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $orange transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s; }

    &:nth-child(2) {
        animation-delay: -0.3s; }

    &:nth-child(3) {
        animation-delay: -0.15s; } }

@keyframes searchResultsRing {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

.arrowIconRotation {
    transition: 0.5s; }

.premiumText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #F66322; }

.premiumIcon {
    margin-right: 10px;
    margin-top: -7px; }
